body {
  background-color: #fff;
}

/* Body */
.flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
}

.text-align-center {
  text-align: center;
}

.align-items-center {
  align-items: center;
}

.dir-column {
  flex-direction: column;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.justify-start {
  justify-content: start !important;
}

.justify-end {
  justify-content: end;
}

.gap {
  gap: 35px !important;
}

.ant-layout-sider-zero-width-trigger {
  /* Sider collapse toggle trigger */
  top: 15px !important;
}

.main-container {
  text-align: center;
  color: white;
}

.z-index {
  z-index: 1;
}

.credentials-container {
  border-radius: 25px;
  padding: 40px 70px 60px 70px;
}

.logo-container {
  margin: 20px;
}

.width-auto {
  width: auto !important;
}

.margin-left {
  margin-left: 5px;
}

.basic-margin {
  margin: 3rem 0rem 3rem 0rem;
}

.margin-top {
  margin-top: 1.5rem;
}

.margin-bottom {
  /* Login Remember me and Forgot password */
  margin-bottom: 1.5rem;
}

.label-bottom-m {
  margin-bottom: 15px !important;
}

/* FORM */

.input-fields {
  height: 3.2rem;
  border-radius: 12px;
  font-size: larger;
}

.font-size-large {
  font-size: larger !important;
}

.basic-input-width {
  /* input field and button static width */
  width: 350px;
}

.label-size-weight {
  font-size: medium;
  font-weight: 700;
}

.link {
  /* Link color */
  color: #6080ff;
}

.pointer {
  cursor: pointer;
}

.min-height {
  /* Min-Height For Cards display in lab repo in path='/' */
  min-height: 300px;
}

@media screen and (max-width: 500px) {

  /* For input labels responsive */
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* ADMIN FORMS */
.reg-btn-m {
  margin: 34px 0px 0px 25px;
}

@media screen and (max-width: 767px) {
  .reg-btn-m {
    margin: 15px 0px;
  }
}

.regformTittle {
  font-size: 35px;
  margin: 13px 13px 13px 45px;
  /* background-color: #9cd8f4; */
  /* text-decoration: underline; */
}

.labelHeadings {
  color: #0b4a72;
  font-weight: 650;
}

.ant-input,
.ant-select,
.ant-input-affix-wrapper {
  border: 1px solid #9cd8f4;
  outline: transparent;
  border-radius: 8px;
}

/* THEME COLORS */
/* BG */
.bg-skills-da {
  background-color: #0b4a72;
}

.bg-hammer-dark {
  background-color: #262161;
}

.bg-hammer-blue {
  background-color: #9cd8f4;
}

.bg-motto-black {
  background-color: #000000;
}

/* Font Colors */
.color-skills-da {
  color: #0b4a72;
}

.color-hammer-dark {
  color: #262161;
}

.color-hammer-blue {
  color: #6080ff;
}

.color-motto-black {
  color: #000000;
}

/* Responsiveness for inputs */
@media screen and (max-width: 320px) {
  .credentials-container {
    padding: 0;
  }

  .basic-input-width {
    width: 200px;
  }

  .justify-space-between {
    flex-direction: column;
  }
}

@media screen and (min-width: 320px) {
  .credentials-container {
    padding: 20px;
  }

  .basic-input-width {
    width: 200px;
  }

  .justify-space-between {
    flex-direction: column;
  }
}

@media screen and (min-width: 375px) {

  .basic-input-width {
    width: 300px;
  }

  .justify-space-between {
    flex-direction: row;
  }
}

@media screen and (min-width: 500px) {
  .credentials-container {
    padding: 40px 70px 60px 70px;
  }

  .basic-input-width {
    width: 350px;
  }
}

/* ANT DESIGN MODIFICATION */

.ant-card-head-title {
  font-weight: 650;
}

.ant-typography {
  font-size: 18px;
}

.ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-form-item-explain-error {
  margin: 10px;
}

.DraftEditor-editorContainer {
  height: 350px !important;
}

.ant-layout-header {
  /* Header custom Background Color */
  /* background-color: #ffffff; */
}

.ant-layout .ant-layout-sider-children {
  padding-top: 16.099999999999994px;
}

.ant-btn {
  /* Register button custom color */
  /* color: #fff; */
}

.spin-height {
  min-height: 760px;
}

/* Headers NAV BAR ANTD custom*/
.ant-tabs-tab-btn {
  font-size: 16px;
  /* Top bar tab custom color  */
  color: #fff;
}

.tabs-reg-button {
  margin-right: 16px;
}

.ant-row-rtl .tabs-reg-button {
  width: 150px;
  margin: 10px 60px 10px 15px;
}

.ant-tabs-nav-more {
  background-color: #fff !important;
}

/* Side Nav bar Admin Panel ANTD custom*/

.ant-layout-sider-trigger {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ant-menu-title-content,
svg>.sidebarIcon>.ant-menu-item-icon>path {
  /* Side Drawer custom color */
  /* color: #9cd8f4; */
  /* color: white; */
  font-weight: bold;
}

/* Headers NAV BAR */

.homeLogo {
  width: 150px;
  margin: 10px 50px 10px 15px;
}

.menubarRightButtons,
.modelContents {
  display: flex;
  gap: 10px;
}

.modelContents {
  align-items: center;
  justify-content: center;
}

.menu {
  background-color: aliceblue;
  color: #0b4a72;
  margin: 1px 0 50px -50px;
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 950px) {
  .homeLogo {
    margin: 15px 0;
    float: right
  }
}


/* Side Nav bar Admin Panel */

.sidebarIcon {
  width: 25px;
  height: 25px;
}

/* TEXT EDITOR STYLES*/
.wrapper-class {
  padding: 1.8rem;
  border: 1px solid #999999;
  border-radius: 10px;
  min-width: 95%;
  /* background-color: #262161; */
  /* background-color: #0b4a72; */
  /* background-color: #9cd8f4; */
}

.editor-class {
  background-color: rgb(243, 243, 243);
  padding: 2rem;
  /* border: 1px solid #6080ff; */
}

.toolbar-class {
  border: 10px solid #b92ccc;
  /* margin: 20px; */
}

/*addlab label heading and buttons */
.labelHeadings {
  color: #0b4a72;
  font-weight: 650;
  display: flex;
  align-items: center;
  gap: 35px;
}

.toggleButton {
  margin-top: 5px;
  background-color: transparent;
  color: #0b4a72;
  border: #0b4a72;
  font-size: 22px;
}